import { Component } from '@angular/core';
import { faArrowLeft, FaIconsRegistry, faMenuLeft, faMenuRight } from '@ui-core/icon';

@Component({
  selector: 'igps-file-info-header',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'IGPS-Client-File-Info-Header-UI';

  constructor(private faIconsRegistry: FaIconsRegistry) {
    this.faIconsRegistry.registerIcons([faMenuLeft, faMenuRight, faArrowLeft]);
  }

}

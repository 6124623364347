import { EventEmitter } from "@angular/core";
import { IFileInfo } from "../models/file-info.interface";
import { ProcessDataModel } from "../models/processDataModel";
import { fileInfo, processData } from "../components/file-info-header/file-info-header.component";


export const GetTaskBasicInfo: () => IFileInfo = () => fileInfo;
export const TaskBasicInfoEvent: EventEmitter<{ isBackgroundRefresh: boolean, taskinfo: IFileInfo }> = new EventEmitter<{ isBackgroundRefresh: boolean, taskinfo: IFileInfo }>();
export const ReloadTaskBasicInfoEvent: EventEmitter<void> = new EventEmitter<void>();
export const NavigateBackEventStart: EventEmitter<void> = new EventEmitter<void>();
export const NavigateBackEventWait: EventEmitter<void> = new EventEmitter<void>();
export const NavigateBackEventConfirm: EventEmitter<void> = new EventEmitter<void>();
export const resetTimerEvent: EventEmitter<void> = new EventEmitter<void>();
export const GetProcessData: () => ProcessDataModel = () => processData;
export const ProcessDataEvent: EventEmitter<ProcessDataModel> =new EventEmitter<ProcessDataModel>();

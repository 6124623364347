import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { IFileInfo } from '../models/file-info.interface';

@Injectable({
  providedIn: 'root'
})
export class FileInfoService {

  readonly GTbaseUrl = `${environment.genericTaskBase.apiUrl}`;
  readonly baseUrl = `${environment.igpsBase.apiUrl}`;

  constructor(private httpClient: HttpClient) { }

  getFileInfo(taskId: string): Observable<IFileInfo> {
    return this.httpClient.get<IFileInfo>(`${this.GTbaseUrl}/gentask/v1/task/${taskId}/basicinfo`);
  }
  getStatusAlert(processId:string,statusId:number) {
    return this.httpClient.get(`${this.baseUrl}/domaindata/prdprocess/v1/process/${processId}/alertText/${statusId}`);
  }
}

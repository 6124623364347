import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ProcessDataModel } from '../models/processDataModel';

@Injectable({
  providedIn: 'root'
})
export class ProcessService {

  readonly igpsUrl = `${environment.igps.apiUrl}`;

  constructor(private httpClient: HttpClient) { }

  getProcessById(processId: string): Observable<ProcessDataModel> {
    return this.httpClient.get<ProcessDataModel>(`${this.igpsUrl}` + `/prdprocess/v1/id/${processId}`);
  }

}

import { SpinnerModule } from '@igps/spinner';
import { ToastModule } from '@ui-core/toast';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { IconModule } from '@ui-core/icon';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FileInfoHeaderComponent } from './components/file-info-header/file-info-header.component';
import { HttpInterceptor } from './interceptors/header-interceptor.interceptor';
import { PipeModule } from '@igps/pipe';

@NgModule({
  declarations: [
    AppComponent,
    FileInfoHeaderComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    IconModule,
    ToastModule,
    SpinnerModule,
    HttpClientModule,
    PipeModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptor,
      multi: true,
    },
    {
      provide: ErrorHandler,
      useClass: HttpInterceptor
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

import { ErrorHandler, Injectable } from '@angular/core';
import { HttpRequest, HttpHeaders, HttpHandler, HttpEvent, HttpInterceptor as Interceptor } from '@angular/common/http';
import { v4 as uuidv4 } from 'uuid';
import { getToken,getUser } from '@igps/client-root-config';
import { catchError, from, lastValueFrom, Observable, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { ErrorModel } from '../models/errorModel';
import { LoggerService } from '../services/logger.service';

@Injectable()
export class HttpInterceptor implements Interceptor,ErrorHandler {
  constructor(private router: Router, private loggerService: LoggerService) {
  }
  correlationId = uuidv4();
  handleError(error: any, errorType = "UI"): void {
    const errorInfo: ErrorModel = {
      applicationName: "IGPS-Client-File-Info-Header-UI",
      loggedInUserName: getUser(),
      error: errorType === "UI" ? error?.stack : error?.message,
      url: this.router?.url
    }
    this.loggerService.logApplicationErrors(errorInfo).subscribe();
  }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return from(this.handle(req, next)).pipe(
      catchError((err) => {
        if (!err?.url?.endsWith('log/v1')) {
          this.handleError(err, "API");
        }
        return throwError(()=>{
          return err;
        });
      }));
  }

  async handle(req: HttpRequest<any>, next: HttpHandler): Promise<HttpEvent<any>> {
    const token = await getToken();
    const headers = new HttpHeaders({
      'Cache-control': 'no-cache',
      Expires: '0',
      Pragma: 'no-cache',
      originapp: 'client',
      'Correlation-Id': this.correlationId,
      Authorization: `Bearer ${token}`,
    });

    if (req.body !== FormData) {
      headers.set('Content-Type', 'application/json');
    }
    const authReq = req.clone({
      headers,
    });
    return lastValueFrom(next.handle(req.clone(authReq)));
  }
}

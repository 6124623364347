<lib-toast [toastMessage]="errorMessage" [error]="isError"></lib-toast>
<igps-lib-spinner [showSpinner]="showSpinner"></igps-lib-spinner>

<div class="main-div" *ngIf="fileInfo">
  <div class="flex-row">
    <div class="back-icon">
      <lib-icon name="arrow_left" size="standard" (click)="onBack()"></lib-icon>
    </div>
    <div class="main-header">
      <div class="top">{{ fileInfo.branchName }}</div>
      <div class="bottom">
        {{ fileInfo.branchOfficerName }} ({{ fileInfo.branchOfficerType }})
      </div>
    </div>
  </div>
  <div class="flex-row">
    <div class="info-details">
      <div class="top">File Number</div>
      <div class="bottom">{{ fileInfo.fastFileNumber }}</div>
    </div>
    <div class="divider"></div>
    <div class="info-details">
      <div class="top">Transaction Type</div>
      <div class="bottom">{{ fileInfo.transcationType }}</div>
    </div>
    <div class="divider"></div>
    <div class="info-details">
      <div class="top">Settlement Date</div>
      <div class="bottom">
        {{
          fileInfo.settlementDate
            | convertTime : "local" : "local" : "dd/MMM/yyyy"
        }}
        <div class="status">
          <span> {{ fileInfo.fileStatus.value }} </span>
        </div>
      </div>
    </div>
  </div>
  <div class="panel-icon-div">
    <div class="panel-icon" (click)="ontogglePanel()">
      <lib-icon *ngIf="!isExpanded" name="menu_left" size="standard"></lib-icon>
      <lib-icon *ngIf="isExpanded" name="menu_right" size="standard"></lib-icon>
    </div>
  </div>
</div>
